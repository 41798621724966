<template>
  <div class="foot_menu">
    <div class="ul">
      <template v-for="(item, index) in menuList">
        <template v-if="!item.url.includes('http')">
          <router-link
            :to="item.url"
            :class="item.url == $route.path ? 'active' : ''"
            class="item"
          >
            <img
              :src="item.sel_pic"
              class="icon"
              v-if="item.url == $route.path"
            />
            <img :src="item.pic" class="icon" v-else />
            <p class="name">{{ item.title }}</p>
          </router-link>
        </template>
        <template v-else>
          <a
            :href="item.url"
            target="_blank"
            class="item"
            :class="item.url == $route.path ? 'active' : ''"
          >
            <img
              :src="item.sel_pic"
              class="icon"
              v-if="item.url == $route.path"
            />
            <img :src="item.pic" class="icon" v-else />
            <p class="name">{{ item.title }}</p>
          </a>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { config } from "@/static/js/config.js";
import axios from "axios";
export default {
  data() {
    return {
      menuList: [],
      href: window.location.origin,
    };
  },
  props: {
    activeIndex: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    // //config
    var li1 = localStorage.getItem("menuList");
    if (li1) {
      var i2 = JSON.parse(li1);
      if (i2) {
        this.menuList = i2;
      }
    }
    this.getNavigation();
  },
  methods: {
    getNavigation() {
      axios({
        method: "get",
        url: this.href + "/api" + "/navigation/list",
        headers: {
          token: this.vuex_token,
          // "content-type": "application/json"
        },
        params: {},
      })
        .then((res) => {
          if (res.data.error_code == 1) {
            var data = res.data.data;
            var list = [];
            data.map((item) => {
              if (item.is_show) {
                list.push(item);
              }
            });
            console.log("菜单列表", list);
            this.menuList = list;
            localStorage.setItem("menuList", JSON.stringify(list));
          } else {
            this.$toast(res.data.msg);
            this.onErrHttp(res);
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="less" scoped></style>
