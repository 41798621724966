import axios from "axios";

// mixins/myMixin.js
export default {
  data() {
    return {
      data: {
        vuex_token: "",
      },
    };
  },
  mounted: function () {
    this.vuex_token = localStorage.getItem("vuex_token");
    if (!this.vuex_token) {
      if (this.$route.path !== "/login") this.$router.push("/login");
      return false;
    }
    this.getRealAuthInfo();
  },

  methods: {
    getRealAuthInfo() {
      axios({
        method: "get",
        url: window.location.origin + "/api" + "/user/real-auth-info",
        headers: {
          token: this.vuex_token,
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          // var res = JSON.parse(res1);
          if (res.data.error_code == 1) {
            return;
          }
          this.$dialog
            .alert({
              title: "温馨提示",
              message: "请先实名认证",
            })
            .then(() => {
              this.$router.push("/user/realauth");
            });
        })
        .catch((error) => {
          // 请求失败处理
          this.loading = false;
          console.log(error);
        });
    },
  },
};
