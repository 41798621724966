<template>
  <div class="container flex flex-col h-screen">
    <van-popup
      class="bg-transparent"
      v-model="dialog"
      style="overflow-y: visible"
      position="center"
      :closeable="false"
    >
      <div class="bg-white rounded-3xl relative">
        <img class="notice-title--img" src="@/static/img/bg_notice_top.png" />
        <div class="notice-title flex justify-center text-2xl items-center">
          公告消息
        </div>
        <div class="bg-white p-2 m-0 h-80 overflow-y-auto">
          <p v-html="problem" class="text-[16px]"></p>
        </div>
        <div
          class="bg-white text-white rounded-b-3xl py-4 text-[16px] flex justify-center"
          @click="onOff"
        >
          <div class="notice-btn">确定</div>
        </div>
      </div>
    </van-popup>
    <van-popup
      class="bg-transparent"
      v-model="dialogIntroduce"
      style="overflow-y: visible"
      position="center"
      :closeable="false"
    >
      <div class="bg-white rounded-3xl relative">
        <img class="notice-title--img" src="@/static/img/bg_notice_top.png" />
        <div class="notice-title flex justify-center text-2xl items-center">
          公告消息
        </div>
        <div class="bg-white p-2 m-0 h-80 overflow-y-auto">
          <p v-html="introduce" class="text-[16px]"></p>
        </div>
        <div
          class="bg-white text-white rounded-b-3xl py-4 text-[16px] flex justify-center"
          @click="onOffIntroduce"
        >
          <div class="notice-btn">确定</div>
        </div>
      </div>
    </van-popup>
    <div class="top-content p-4">
      <div class="ziye_toub">
        <div class="kefu">
          <img src="@/static/img/home_slices/logo.png" alt="" />
        </div>
      </div>
    </div>
    <div class="flex-grow overflow-hidden">
      <div class="page-content">
        <div class="top-content p-4 rounded-b-lg">
          <van-notice-bar
            class="top-content--notice mb-4"
            left-icon="volume-o"
            background="#fff"
            color="#333333"
          >
            五行财富，为幸福人生加油
          </van-notice-bar>
          <div class="menu">
            <router-link class="it" to="/user/invitathumb">
              <img src="@/static/img/home_slices/icon-index-yq.png" />
              <div class="text-base">邀请好友</div>
            </router-link>
            <router-link class="it" to="/user/contactus">
              <img src="@/static/img/home_slices/icon-index-qun.png" />
              <div class="text-base">官方群聊</div>
            </router-link>
            <!-- <a class="it" :href="vuex_system.kefu_url"> -->
            <router-link class="it" to="/user/contactus1">
              <img src="@/static/img/home_slices/icon-index-kf.png" />
              <div class="text-base">官方客服</div>
            </router-link>
            <!-- </a> -->
          </div>

          <div style="margin-top: 3px" class="banner_shouy">
            <van-swipe :autoplay="3000" indicator-color="white">
              <van-swipe-item v-for="(item, index) in adList" :key="index">
                <div>
                  <img :src="item.pic" class="img" />
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
        </div>
        <div class="m-4">
          <router-link to="/user/guarantee">
            <img
              class="w-[100%]"
              style="border-radius: 10px"
              src="@/static/img/zjaq.png"
            />
          </router-link>
        </div>
        <div class="m-4" @click="handleJh">
          <img
            class="w-[100%]"
            style="border-radius: 10px"
            src="@/assets/jhk.png"
          />
        </div>

        <div class="p-4">
          <div class="news-head">
            五行财富
            <span>梦想从此刻开始</span>
          </div>
          <div class="video-box mt-4">
            <video class="video" controls width="100%" height="200">
              <source src="@/static/img/home_m.mp4" type="video/mp4" />
              <!-- 提供多个视频源以支持不同的浏览器 -->
              <!-- <source src="video.webm" type="video/webm"> -->
              Your browser does not support the video tag.
            </video>
          </div>
          <div class="news-head">
            行业新闻
            <span>今日更新</span>
          </div>

          <div class="news-box">
            <a
              v-for="(item, index) in topList"
              :key="index"
              :href="item.href"
              class="item"
            >
              <span
                class="index"
                :class="{
                  top1: index === 0,
                  top2: index === 1,
                  top3: index === 2,
                }"
                >{{ index + 1 }}</span
              >
              <p class="title">
                {{ item.title }}
              </p>
              <img
                style="width: 15px; height: 15px"
                src="@/static/img/home_slices/热.png"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <FootMenu :activeIndex="1" />
  </div>
</template>

<script>
import { config } from "@/static/js/config.js";
import axios from "axios";
import FootMenu from "../common/FootMenu";
import initMixin from "../mixins/initMixin.js";
import realAuthInfoMixin from "@/mixins/realAuthInfoMixin";
export default {
  mixins: [initMixin, realAuthInfoMixin],
  components: { FootMenu },
  data: () => {
    return {
      title: "首页",

      dialog: false,
      dialogIntroduce: false,
      loading: false,
      stock_price: 0,
      problem: "",
      introduce: "",
      href: window.location.origin,
      adList: [],
      vuex_token: "",
      kfUrl: "https://psslk.org/joinchat/002a1f4e084514e78dd1cf832d1ccaa6dc5",
      vuex_system: {},
      user: {},
      topList: [
        {
          title: "央行、发改委、财政部、金融监管总局最新部署！",
          href: "./index/news1.html?v=3",
        },
        {
          title: "2024年一季度财政收支情况",
          href: "./index/news2.html?v=3",
        },
        {
          title: "腾讯的上市历程：2003年直接造就了5个亿万富翁和7个千万富翁",
          href: "./index/news3.html?v=3",
        },
        {
          title: "财政部：进一步加强扶贫资金监管 保障决战决胜脱贫攻坚",
          href: "./index/news4.html?v=3",
        },
      ],
    };
  },
  mounted: function () {
    this.getAdList();
    this.getSystem();
    this.getUserInfo();
  },
  methods: {
    handleJh() {
      if (this.user.is_jh != 0) {
        return this.$router.push(`/jh`);
      }

      this.$dialog
        .confirm({
          title: "温馨提示",
          message: "激活芯片",
          confirmButtonText: "立即激活",
          confirmButtonColor: "#FF0000",
        })
        .then(() => {
          this.$router.push(`/welfare/jh-pay`);
        });
    },
    onSignIn(e) {
      // 2未签到 1已签到
      if (e == 1) {
        this.$toast("今日已签到完成！");
        return false;
      }
      let params = {};
      axios({
        method: "post",
        url: this.href + "/api" + "/user/sign",
        headers: {
          token: this.vuex_token,
          "content-type": "application/json",
        },
        data: params,
      })
        .then((res) => {
          if (res.data.error_code == 1) {
            var data = res.data.data;
            console.log("登录结果", data);
            this.$toast.success("签到成功!");
            this.getUserInfo();
          } else {
            this.$toast(res.data.msg);
            this.onErrHttp(res);
          }
        })
        .catch((err) => console.log(err));
    },
    getUserInfo() {
      let data = {};

      axios.defaults.headers.token = this.vuex_token;
      axios.defaults.headers["content-type"] =
        "application/x-www-form-urlencoded;charset=utf-8";
      axios({
        method: "get",
        url: this.href + "/api" + "/user/user-info",
      })
        .then((res) => {
          // var res = JSON.parse(res1);
          if (res.data.error_code == 1) {
            var data = res.data.data;
            console.log("用户信息", data);
            // this.$toast('登录成功！')
            localStorage.setItem("vue_userInfo", JSON.stringify(data));
            this.user = data;
          } else {
            this.$toast(res.data.msg);
            this.onErrHttp(res);
          }
        })
        .catch((error) => {
          // 请求失败处理
          console.log(error);
        });
    },
    getSystem() {
      let params = {};
      axios({
        method: "get",
        url: this.href + "/api" + "/site/system",
        headers: {
          token: this.vuex_token,
          // "content-type": "application/json"
        },
        data: params,
      })
        .then((res) => {
          console.log("结果", res);
          if (res.data.error_code == 1) {
            var data = res.data.data;
            this.vuex_system = data;
            this.problem = data.problem;
            this.introduce = data.introduce;
            this.stock_price = data.stock_price;
            console.log("系统配置", data, data.stock_price);
            localStorage.setItem("vuex_system", JSON.stringify(data));

            if (data.problem && data.problem != "") {
              this.dialog = true;
            }
          } else {
            this.$toast(res.data.msg);
            this.onErrHttp(res);
          }
        })
        .catch((err) => console.log(err));
    },
    onOff() {
      this.dialog = false;
      if (this.introduce && this.introduce != "") {
        this.dialogIntroduce = true;
      }
    },
    onOffIntroduce() {
      this.dialogIntroduce = false;
    },
    getAdList() {
      let data = {};

      axios.defaults.headers.token = this.vuex_token;
      axios.defaults.headers["content-type"] =
        "application/x-www-form-urlencoded;charset=utf-8";
      axios({
        method: "get",
        url: this.href + "/api" + "/index/ad",
      })
        .then((res) => {
          // var res = JSON.parse(res1);
          console.log("获取广告！", res);
          if (res.data.error_code == 1) {
            var data = res.data.data;
            console.log("获取广告列表", data);
            // this.$toast('登录成功！')
            this.adList = data;
            this.loading = false;
          } else {
            this.$toast(res.data.msg);
            this.onErrHttp(res);
          }
        })
        .catch((error) => {
          // 请求失败处理
          console.log(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
@layer utilities {
  .content-auto {
    content-visibility: auto;
  }
}

.container {
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #f5f5f5;
}

.menu {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  width: 94%;
  flex-wrap: wrap;
  position: relative;
  border-radius: 8px;
  z-index: 1;
}

.menu .title {
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  font-size: 12px;
}

.menu a {
  width: 25%;
  color: #fde0b4;
  padding: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 11px;
}

.menu a img {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
}

.z-con {
  width: 100%;

  margin: 20px auto;

  background-color: #f5f4f0;
}

.z-con .pro {
  color: #000;

  font-size: 18px;

  height: 40px;

  line-height: 40px;

  padding-left: 10px;
}

.z-con .con {
  line-height: 1.8;

  font-size: 13px;

  color: #333;

  margin-bottom: 30px;
}

.z-con .con img {
  width: 100%;
}

.logo img {
  width: 50%;
}

.open {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  bottom: 0px;
  overflow: hidden;
  word-break: break-all;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog {
  width: 80%;
  background-color: #ffffff;
  opacity: 1;
  color: #333;
  padding: 0px;
  color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.dialog .title {
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  background-color: rgb(87, 78, 160);
  font-weight: bold;
}

.dialog .content {
  line-height: 1.5;
  font-size: 14px;
  color: #000000;
  padding: 10px;
  min-height: 100px;
  max-height: 400px;
  overflow-y: scroll;
}

.dialog .config {
  width: 100%;
  list-style: none;
  background: linear-gradient(to bottom, #f8f8f8, #f8f8f8);
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: block;
  font-size: 14px;
  color: rgb(87, 78, 160);
}

.close {
  display: none;
}

.note {
  height: 40px;
  line-height: 40px;
  background-color: #fffbe8;
  color: #ffa20b;
  display: flex;
  // background: url("@/static/mobile/new/icon/18.png") no-repeat left center
  //   #fffbe8;
}

.video-box {
  width: 100%;
  height: 270px;
  background: url("@/static/img//home_slices/video_bg.png") no-repeat;
  background-size: 100% auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 50px 20px 0;
}

.video-box video {
  height: 200px;
}

.note span {
  margin-left: 40px;
}

.top-content {
  background-color: #c9362b;
}

.top-content--notice {
  border-radius: 0 30px 30px 30px;
}

.my_kuagn .my_k {
  padding-top: 5px;
}

.ziye_toub {
  height: auto;
}

.ziye_toub .kefu {
  display: block;
  float: left;
}

.ziye_toub .kefu img {
  height: 25px;
}

.maijia {
  height: 200px;
}

.maijia .list {
  height: 200px;
  margin-top: 20px;
}

.maijia .list .left {
  width: 100%;
}

.maijia .list .left img {
  width: 100%;
  border-radius: 15px 15px 0 0;
}

.maijia .list .right {
  z-index: 20;
  display: flex;
  color: #999;
}

.maijia .list .right span {
  width: 33%;
  text-align: center;
}

.maijia .list .right span b {
  color: #ff5e48;
  font-size: 16px;
  padding-top: 20px;
}

.maijia .list .buy {
  width: 82%;
  background: #008fff;
  height: 44px;
  line-height: 44px;
  color: #fff;
  border-radius: 22px;
  font-size: 16px;
  margin: 15px auto;
  text-align: center;
}

.maijia .list .buy a {
  color: #ffffff;
}

.maijia .list .title {
  width: 90%;
  height: 60px;
  line-height: 60px;
  text-align: left;
  display: block;
  color: #232529;
  font-size: 18px;
  font-weight: 700;
  padding-left: 5%;
}

.my_kuagn {
  width: 96%;
  margin: 10px auto;
}

.news-head {
  font-size: 16px;
  padding-top: 15px;
  font-weight: bold;
  text-align: left;
  padding-left: 10px;
  vertical-align: bottom;
}

.news-head span {
  font-size: 12px;
  color: #999999;
}

.advertising-img {
  width: 100%;
  margin: 15px 0px;
}

.swiper-wrapper .img {
  width: 94%;
  border-radius: 0px;
  background-color: #f8f8f8;
  min-height: 140px;
  border-radius: 4px;
}

.add-img {
  display: block;
  width: 94%;
  margin: auto;
  overflow-x: hidden;
  margin-top: 10px;
}

.add-img img {
  width: 100%;
  margin: auto;
}

.banner_shouy {
  width: 100%;
}

.banner_shouy .img {
  /* width: 94%; */
  background-color: #fff;
  /* margin-left: 3%; */
  border-radius: 12px;
  margin-top: 10px;
}

.news-box {
  padding: 2%;
  background: url("@/static/img//home_slices/top.png") no-repeat;
  background-size: 100% auto;
  background-color: rgba(255, 255, 255, 0.8);
  margin: auto;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.news-box .item {
  width: 100%;
  padding: 10px 0px;
  position: relative;
  display: flex;
}

.news-box .index {
  margin: 0 10px;
  font-weight: 800;
}

.news-box .top1 {
  color: #ff0000;
}

.news-box .top2 {
  color: #ec664a;
}

.news-box .top3 {
  color: #f8b551;
}

.news-box .title {
  font-size: 13px;
  font-weight: 550;
  margin-top: 0px;
  color: #000;
  line-height: 22px;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.news-box .lin-text {
  font-size: 12px;
  margin-top: 0px;
  color: #666;
  line-height: 20px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-box .img-box {
  width: 30%;
}

.dialog .content img {
  max-width: 100%;
}

.notice-title {
  background-size: 100%;
  background-repeat: no-repeat;
  width: 300px;
  padding-top: 30px;
  margin-bottom: 10px;
}

.notice-title--img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
}

.notice-btn {
  background-size: 100%;
  background-repeat: no-repeat;
  width: 240px;
  background-color: #e60012;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.van-popup::before,
.van-popup::after {
  display: none;
}
</style>
