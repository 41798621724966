// mixins/myMixin.js
export default {
  data() {
    return {};
  },
  mounted: function () {
    this.vuex_token = localStorage.getItem("vuex_token");
    if (!this.vuex_token) {
      console.log("没有登录");
      if (this.$route.path !== "/login") this.$router.push("/login");

      return false;
    } else {
      console.log("已登录", this.vuex_token);
    }
  },
  methods: {
    onErrHttp(res) {
      if (res.data.error_code == 401) {
        if (this.$route.path !== "/login") this.$router.push("/login");
        return true;
      }
    },
  },
};
